import React from 'react';
import { useTranslation } from 'react-i18next';
import AppRoot from './AppRoot';
import ROUTES from '../constants/routes';
import { Switch, Route } from 'react-router-dom';

import lazyRetry from '../helpers/lazyRetry';

const LoginPage = lazyRetry(() => import('../components/login/LoginPage'));
const ForgotPassword = lazyRetry(() => import('../components/login/ForgotPassword'));
const NewPassword = lazyRetry(() => import('../components/login/NewPassword'));
const Register = lazyRetry(() => import('../components/login/Register'));
const Onboarding = lazyRetry(() => import('../components/login/Onboarding'));
const Complete = lazyRetry(() => import('../components/login/Complete'));

const Frontpage = lazyRetry(() => import('../components/frontpage'));

const Purchase = lazyRetry(() => import('../components/purchase/Purchase'));
const PurchaseSAProComplete = lazyRetry(() => import('../components/purchase/PurchaseSAProComplete'));

import ErrorPage from '../components/errorpages/ErrorPage';

export default function UnauthenticatedApp() {
  const { t } = useTranslation();
  return (
    <AppRoot home="/">
      <Switch>
        <Route exact path={ROUTES.LOGIN}>
          <LoginPage />
        </Route>

        <Route exact path={ROUTES.FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>

        <Route exact path={ROUTES.NEW_PASSWORD}>
          <NewPassword />
        </Route>

        <Route exact path={ROUTES.REGISTER.DETAILS}>
          <Register />
        </Route>

        <Route exact path={ROUTES.REGISTER.COMPLETE}>
          <Complete />
        </Route>

        <Route exact path={ROUTES.REGISTER.START}>
          <Onboarding />
        </Route>

        {/*
        <Route exact path={ROUTES.PURCHASE}>
          <Purchase role="owner" />
        </Route>
        */}

        <Route exact path={ROUTES.PURCHASE_SA_PRO}>
          <Purchase role="coach" />
        </Route>

        <Route exact path={ROUTES.PURCHASE_SA_PRO_COMPLETE}>
          <PurchaseSAProComplete />
        </Route>

        <Route exact path="/">
          <Frontpage />
        </Route>

        <Route>
          <ErrorPage message={t('common.page-not-found')} home="/" />
        </Route>

      </Switch>
    </AppRoot>
  );
}